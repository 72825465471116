import { template as template_71d29d4746bc47d7bf6e428916b32d3a } from "@ember/template-compiler";
const WelcomeHeader = template_71d29d4746bc47d7bf6e428916b32d3a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
