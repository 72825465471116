import { template as template_1d5067af210e47599d6d433ff4c8ac74 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_1d5067af210e47599d6d433ff4c8ac74(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
