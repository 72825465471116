import { template as template_9ce63a4f4e694c2d94a38d006c37856d } from "@ember/template-compiler";
const FKLabel = template_9ce63a4f4e694c2d94a38d006c37856d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
