import { template as template_b4071b5543c44d5e832919c6d6f2db0a } from "@ember/template-compiler";
const FKText = template_b4071b5543c44d5e832919c6d6f2db0a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
